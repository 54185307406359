import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Nt4yKt8i8II">
    <SEO title="Rejected, but Accepted - Touching Holiness" />
  </Layout>
)

export default SermonPost
